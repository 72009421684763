import React, { useState, useEffect, useRef } from 'react';
import { FaInstagram, FaWhatsapp, FaMapMarkerAlt, FaPlay, FaPause, FaInfoCircle } from 'react-icons/fa';
import instagramLogo from '../../assets/instagram-logo.png';
import whatsappLogo from '../../assets/whatsapp-logo.png';
import gmaps from '../../assets/gmaps.png';
import mantraAudio from '../../assets/mantra-audio.mp3';

const Footer = () => {
  const [mantraText, setMantraText] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [explanationText, setExplanationText] = useState('');
  const audioRef = useRef(null);
  const fullMantra = "Om Asato Maa Sad-Gamaya | Tamaso Maa Jyotir-Gamaya | Mrtyor-Maa Amrtam Gamaya | Om Shaantih Shaantih Shaantih ||";
  const explanationFullText = `Lead us from the unreal to the real\nLead us from darkness to light\nLead us from death to immortality\nAum peace, peace, peace!\nThis ancient mantra embodies the essence of Mantra Mountain's philosophy. It is a prayer for spiritual transformation and enlightenment, guiding us to transcend illusions and falsehoods, to gain knowledge and clarity, and to overcome the fear of death, achieving eternal peace. Just as our treks lead you through the majestic Himalayas, this mantra leads you to inner peace and positive energy.`;

  useEffect(() => {
    setMantraText(fullMantra);
  }, []);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.currentTime = 40; // Skip first 40 seconds
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleExplanation = () => {
    setShowExplanation(!showExplanation);
    if (!showExplanation) {
      let index = 0;
      const intervalId = setInterval(() => {
        setExplanationText((prev) => prev + explanationFullText[index]);
        index++;
        if (index === explanationFullText.length) {
          clearInterval(intervalId);
        }
      }, 20); // Faster typing effect
    } else {
      setExplanationText('');
    }
  };

  return (
    <footer className="bg-gradient-to-t from-gray-900 via-indigo-900 to-gray-800 text-white py-12 relative overflow-hidden">
      {/* Wave Background */}
      <div className="absolute inset-x-0 bottom-0 z-0">
        <svg
          className="w-full h-32 opacity-70"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="animate-wave">
            <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
            <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
            <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
            <use href="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </div>

      <div className="container mx-auto px-4 max-w-7xl relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Left: Branding & Mantra */}
          <div className="space-y-6">
            <h3 className="text-2xl font-bold text-indigo-300">Mantra Mountain</h3>
            <p className="text-gray-300 text-sm">
              Embark on a transformative journey through the Himalayas.
            </p>
            <div className="bg-gray-800/50 p-4 rounded-lg shadow-lg">
              <p className="text-sm italic text-gray-200 mb-2">{mantraText}</p>
              <div className="flex gap-2">
                <button
                  onClick={handlePlayPause}
                  className="p-2 bg-indigo-600 hover:bg-indigo-700 rounded-full transition-all duration-300"
                  aria-label={isPlaying ? 'Pause Mantra' : 'Play Mantra'}
                >
                  {isPlaying ? <FaPause size={16} /> : <FaPlay size={16} />}
                </button>
                <button
                  onClick={handleExplanation}
                  className="p-2 bg-gray-700 hover:bg-gray-600 rounded-full transition-all duration-300"
                  aria-label="Toggle Mantra Explanation"
                >
                  <FaInfoCircle size={16} />
                </button>
              </div>
              {showExplanation && (
                <div className="mt-4 text-gray-300 text-sm font-light animate-fade-in">
                  {explanationText.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Center: Navigation Links */}
          <div className="space-y-6">
            <h4 className="text-lg font-semibold text-indigo-200">Explore More</h4>
            <ul className="space-y-3 text-gray-300">
              <li>
                <a
                  href="/blog"
                  className="hover:text-indigo-400 transition-colors duration-200"
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="hover:text-indigo-400 transition-colors duration-200"
                >
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  href="/careers"
                  className="hover:text-indigo-400 transition-colors duration-200"
                >
                  Careers
                </a>
              </li>
              <li>
                <a
                  href="/packages"
                  className="hover:text-indigo-400 transition-colors duration-200"
                >
                  Trekking Packages
                </a>
              </li>
            </ul>
          </div>

          {/* Right: Social & Contact */}
          <div className="space-y-6">
            <h4 className="text-lg font-semibold text-indigo-200">Connect With Us</h4>
            <div className="flex gap-4">
              <a
                href="https://www.instagram.com/mantramountain/"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-white/10 rounded-full hover:bg-indigo-600 transition-all duration-300"
              >
                <FaInstagram size={24} />
              </a>
              <a
                href="https://wa.me/message/ONCGLHPEKNRMP1"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-white/10 rounded-full hover:bg-indigo-600 transition-all duration-300"
              >
                <FaWhatsapp size={24} />
              </a>
              <a
                href="https://maps.app.goo.gl/UCkZ5CbK56GjX5Jd6"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-white/10 rounded-full hover:bg-indigo-600 transition-all duration-300"
              >
                <FaMapMarkerAlt size={24} />
              </a>
            </div>
            <p className="text-gray-400 text-sm">
              © {new Date().getFullYear()} Mantra Mountain. All rights reserved.
            </p>
          </div>
        </div>

        {/* Newsletter Signup */}
        <div className="mt-2 text-center">
          <h4 className="text-lg font-semibold text-indigo-200 mb-4">
            Subscribe for Trekking Updates
          </h4>
          {/* <form className="flex flex-wrap justify-center gap-2 max-w-md mx-auto">
            <input
              type="email"
              placeholder="Enter your email"
              className=" rounded-lg w-full sm:placeholder:text-md bg-gray-800 text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-all duration-200"
            />
            <button
              type="submit"
              className=" bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-300 shadow-md"
            >
              Subscribe
            </button>
          </form> */}
        </div>
      </div>

      <audio ref={audioRef} src={mantraAudio} />

      {/* Tailwind Animation Styles */}
      <style jsx>{`
        @keyframes wave {
          0% { transform: translateX(0); }
          100% { transform: translateX(-50%); }
        }
        .animate-wave {
          animation: wave 20s linear infinite;
        }
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        .animate-fade-in {
          animation: fadeIn 0.5s ease-in;
        }
      `}</style>
    </footer>
  );
};

export default Footer;