import React, { useState, useEffect, useRef } from 'react';
import { GoogleGenerativeAI, HarmCategory, HarmBlockThreshold } from "https://cdn.jsdelivr.net/npm/@google/generative-ai@latest/+esm";
import robo from '../../assets/robo.png'; // Assuming you have this asset
import beepSound from './../../assets/beep.mp3'; // Assuming you have this asset

const API_KEY = "AIzaSyB95re0HjI93gC5VwQhi2pnuYbK6JV_SpU";
const genAI = new GoogleGenerativeAI(API_KEY);

const username = "P";
const account_num = "9826112520";

const ENDPOINTS = {
  PACKAGES: "https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/all",
};

const safetySettings = [
  {
    category: HarmCategory.HARM_CATEGORY_HARASSMENT,
    threshold: HarmBlockThreshold.BLOCK_ONLY_HIGH,
  },
  {
    category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
    threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
  },
];

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
    if (isOpen && messages.length === 0) {
      setMessages([{ text: "Hi ask me anything", user: false }]);
    }
  }, [isOpen]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const playBeep = () => {
    const audio = new Audio(beepSound);
    audio.play();
  };

  const addMessage = (text, isUser) => {
    setMessages(prev => [...prev, { text, user: isUser }]);
  };

  const fetchData = async (url) => {
    const response = await fetch(url);
    if (!response.ok) throw new Error(`Failed to fetch ${url}`);
    return response.json();
  };

  const generateContent = async () => {
    if (!input.trim()) return;

    addMessage(input, true);
    setInput('');
    setIsLoading(true);

    try {
      const packageDetails = await fetchData(ENDPOINTS.PACKAGES);

      const model = genAI.getGenerativeModel({
        model: "gemini-1.5-pro",
        systemInstruction: {
          role: "system",
          parts: [
            {
              text: `User: @${username}\nAccount: ${account_num}\nAvailable Trekking Packages: ${JSON.stringify(packageDetails)}\nFormat responses clearly with bullet points.`,
            },
          ],
        },
        safetySettings,
      });

      addMessage("Generating response...", false);
      const result = await model.generateContent({
        contents: [{ role: "user", parts: [{ text: input }] }],
      });

      const response = await result.response;
      const responseText = await response.text();
      
      setMessages(prev => prev.filter(msg => msg.text !== "Generating response..."));
      addMessage(responseText, false);
    } catch (error) {
      console.error("Error:", error);
      addMessage(`Error: ${error.message}`, false);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleChatbot = () => {
    playBeep();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    generateContent();
  };

  return (
    <div className={`fixed bottom-4 right-4 z-50 flex flex-col items-end ${isOpen ? 'w-[350px]' : 'w-auto'}`}>
      <button 
        className="w-16 h-16 rounded-full bg-white shadow-lg flex items-center justify-center hover:bg-gray-50 transition-all duration-300 border border-gray-200"
        onClick={toggleChatbot}
        aria-label={isOpen ? 'Close chat' : 'Open chat'}
      >
        {isOpen ? '✕' : <img src={robo} alt="Chatbot" className="h-14 w-14" />}
      </button>
      
      {isOpen && (
        <div className="mt-4 bg-white rounded-lg shadow-xl border border-gray-200 overflow-hidden w-full">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-xl font-bold text-gray-800">Trekking AI Chatbot</h2>
            <p className="text-sm text-gray-600">Your Trekking Assistant</p>
          </div>
          
          <div className="h-[400px] overflow-y-auto p-4 space-y-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`p-3 rounded-lg ${
                  message.user 
                    ? 'bg-blue-500 text-white ml-auto max-w-[80%]' 
                    : 'bg-gray-100 text-gray-800 mr-auto max-w-[80%]'
                }`}
              >
                {message.text}
              </div>
            ))}
            {isLoading && (
              <div className="flex space-x-2 p-3 bg-gray-100 rounded-lg w-20">
                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-100"></div>
                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-200"></div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          
          <form onSubmit={handleSubmit} className="p-4 border-t border-gray-200">
            <div className="flex space-x-2">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Ask about trekking packages..."
                disabled={isLoading}
                className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button 
                type="submit" 
                disabled={isLoading}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Chatbot;