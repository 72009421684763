import React from 'react';
import { Link } from 'react-router-dom';
import ChatBot from '../Components/ChatBot/ChatBot';
import CampaignSection from '../Components/campaign/CampaignSection';
import Footer from '../Components/footer/Footer';
// import AboutUs from '../Components/AboutUs/AboutUs';
import FloatingTranslator from '../Components/MultiLanguage/FloatingTranslator';
import TravelHimalayaLogo from '../assets/travel-himalayan-logo.png'; // Ensure this path is correct
// import { Helmet } from 'react-helmet-async';

// Component for feature cards to maintain consistency
const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-xl transition-all duration-300 hover:-translate-y-2">
    <span className="text-4xl mb-4 block">{icon}</span>
    <h3 className="text-2xl font-semibold text-gray-800 mb-3">
      {title}
    </h3>
    <p className="text-lg text-gray-600">{description}</p>
  </div>
);
// /ok

// Component for testimonial cards
const TestimonialCard = ({ quote, name, title }) => (
  <div className="bg-white p-8 rounded-lg shadow-md border border-gray-100">
    <p className="text-lg text-gray-700 italic mb-4">"{quote}"</p>
    <div className="flex items-center">
      <div className="w-10 h-10 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 font-bold mr-3">
        {name.charAt(0)}
      </div>
      <div>
        <p className="text-lg font-semibold text-gray-900">{name}</p>
        <p className="text-gray-600">{title}</p>
      </div>
    </div>
  </div>
);

// Component for stats
const StatItem = ({ value, label }) => (
  <div className="bg-white/10 p-6 rounded-lg">
    <p className="text-5xl font-bold text-white mb-2">{value}</p>
    <p className="text-xl text-white/90">{label}</p>
  </div>
);

const About = () => {
  // Features data
  const features = [
    {
      icon: '🏔️',
      title: 'Unplug & Reconnect',
      description: 'Escape the noise and immerse yourself in the untouched beauty of the Himalayas.'
    },
    {
      icon: '👥',
      title: 'Expert-Led Journeys',
      description: 'Our seasoned guides ensure safety and inspire personal growth every step of the way.'
    },
    {
      icon: '❤️',
      title: 'Impact That Matters',
      description: '20% of profits fund disaster relief and sustainable development in remote villages.'
    }
  ];

  // Testimonials data
  const testimonials = [
    {
      quote: "This trek changed my life. The mountains, the people, the peace—it's indescribable.",
      name: "Sarah Mitchell",
      title: "Adventurer"
    },
    {
      quote: "The guides were incredible, and knowing my trip helped others made it even more special.",
      name: "James Richardson",
      title: "Nature Enthusiast"
    }
  ];

  // Stats data
  const stats = [
    { value: "500+", label: "Transformed Lives" },
    { value: "20%", label: "Profits Donated" },
    { value: "15+", label: "Years of Expertise" },
    { value: "100%", label: "Customer Satisfaction" }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-white to-blue-50 pt-20">
      {/* Hero Section with proper overlay and accessibility */}
      <section 
        className="relative bg-cover bg-center h-screen max-h-[600px]" 
        style={{ backgroundImage: "url('/path-to-himalayan-bg.jpg')" }}
        aria-label="Himalayan mountain landscape"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black/60 to-black/40"></div>
        <div className="relative h-full max-w-7xl mx-auto px-6 lg:px-8 flex flex-col justify-center">
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6 max-w-3xl">
            Discover Your True Self with{' '}
            <span className="text-indigo-300">Mantra Mountain</span>
          </h1>
          <p className="text-xl md:text-2xl text-white/90 max-w-2xl mb-8">
            Embark on a life-changing Himalayan journey that awakens your spirit and empowers mountain communities.
          </p>
          <div>
            <Link to="/Packages">
              <button className="bg-indigo-600 text-white px-8 py-3 rounded-lg font-semibold text-lg shadow-lg hover:bg-indigo-700 transition-all duration-300 focus:ring-4 focus:ring-indigo-300 focus:outline-none">
                Start Your Transformation Today
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Mission Statement */}
      <section className="max-w-4xl mx-auto px-6 py-16">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Mission</h2>
          <div className="h-1 w-24 bg-indigo-600 mx-auto mb-8"></div>
        </div>
        
        <div className="grid md:grid-cols-2 gap-8 items-center">
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              More Than Just a Trek
            </h3> 
            <p className="text-lg text-gray-700 mb-4">
              We create transformative Himalayan adventures that redefine your limits and uplift local communities through sustainable tourism practices.
            </p>
            <p className="text-lg text-gray-700">
              Every journey with us is designed to foster personal growth while contributing to the wellbeing of the mountain communities we visit.
            </p>
          </div>
          
          <div className="bg-indigo-50 p-8 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-indigo-900 mb-4">
              Why Choose Us?
            </h3>
            <ul className="text-lg text-gray-700 space-y-3">
              <li className="flex items-start">
                <span className="text-indigo-600 mr-2">✓</span> 
                <span>Exclusive access to untouched landscapes</span>
              </li>
              <li className="flex items-start">
                <span className="text-indigo-600 mr-2">✓</span> 
                <span>Professionally guided self-discovery journeys</span>
              </li>
              <li className="flex items-start">
                <span className="text-indigo-600 mr-2">✓</span> 
                <span>Mindfulness practices in nature's embrace</span>
              </li>
              <li className="flex items-start">
                <span className="text-indigo-600 mr-2">✓</span> 
                <span>Direct contribution to disaster relief efforts</span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-6">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">What Sets Us Apart</h2>
            <div className="h-1 w-24 bg-indigo-600 mx-auto mb-8"></div>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our unique approach combines adventure, mindfulness, and community impact.
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <FeatureCard 
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="bg-gradient-to-r from-indigo-700 to-indigo-900 py-16">
        <div className="max-w-7xl mx-auto px-6">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white mb-4">Our Impact</h2>
            <div className="h-1 w-24 bg-white mx-auto"></div>
          </div>
          
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {stats.map((stat, index) => (
              <StatItem key={index} value={stat.value} label={stat.label} />
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-6">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">What Our Travelers Say</h2>
            <div className="h-1 w-24 bg-indigo-600 mx-auto mb-8"></div>
          </div>
          
          <div className="grid md:grid-cols-2 gap-8">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard 
                key={index}
                quote={testimonial.quote}
                name={testimonial.name}
                title={testimonial.title}
              />
            ))}
          </div>
        </div>
      </section>

      {/* About Us Component */}
    

      {/* Mantra Section */}
      <section className="py-16 bg-indigo-50">
        <div className="max-w-4xl mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Guiding Mantra</h2>
          <div className="h-1 w-24 bg-indigo-600 mx-auto mb-8"></div>
          
          <blockquote className="text-2xl text-gray-700 italic bg-white p-10 rounded-lg shadow-md mb-6">
            "Lead us from the unreal to the real<br />
            Lead us from darkness to light<br />
            Lead us from death to immortality<br />
            Aum peace, peace, peace!"
          </blockquote>
          
          <p className="text-lg text-gray-600">
            This ancient wisdom inspires every step of your journey toward inner peace and clarity.
          </p>
        </div>
      </section>

      {/* Partnership Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-6">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="md:w-1/2">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">Unmatched Expertise & Impact</h2>
              <div className="h-1 w-24 bg-indigo-600 mb-8"></div>
              
              <p className="text-lg text-gray-600 mb-4">
                In partnership with Travel Himalaya Nepal, we deliver exceptional, life-changing adventures that connect you with the heart of the Himalayas.
              </p>
              
              <p className="text-lg text-gray-600">
                Together with humanitarian allies, we empower mountain communities through disaster relief and sustainable growth initiatives that make a lasting difference.
              </p>
            </div>
            
            <div className="md:w-1/2 flex justify-center">
              <img
                src={TravelHimalayaLogo}
                alt="Travel Himalaya Nepal"
                className="w-48 h-48 rounded-full shadow-lg object-contain p-4 bg-white border border-gray-100"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Impact Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-5xl mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Empowering Mountain Communities</h2>
          <div className="h-1 w-24 bg-indigo-600 mx-auto mb-8"></div>
          
          <div className="grid md:grid-cols-3 gap-8 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="w-16 h-16 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 mx-auto mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-3">Infrastructure Rebuilding</h3>
              <p className="text-gray-600">We help rebuild schools, health clinics, and homes in disaster-affected areas.</p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="w-16 h-16 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 mx-auto mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-3">Immediate Aid</h3>
              <p className="text-gray-600">We deliver food, medicine, and essential supplies to remote communities after natural disasters.</p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="w-16 h-16 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 mx-auto mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-3">Sustainable Development</h3>
              <p className="text-gray-600">We invest in long-term projects that create economic opportunities for Himalayan communities.</p>
            </div>
          </div>
          
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            Your journey with Mantra Mountain directly supports these resilient communities, creating a positive impact that extends far beyond your travel experience.
          </p>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gradient-to-r from-indigo-600 to-purple-600 py-16">
        <div className="max-w-4xl mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">
            Ready to Begin Your Himalayan Transformation?
          </h2>
          <p className="text-xl text-white/90 mb-8 max-w-2xl mx-auto">
            Join hundreds of adventurers who've found peace, purpose, and connection through our transformative journeys.
          </p>
          <Link to="/Packages">
            <button className="bg-white text-indigo-600 px-8 py-3 rounded-lg font-semibold text-lg shadow-lg hover:bg-indigo-50 transition-all duration-300 focus:ring-4 focus:ring-white/30 focus:outline-none">
              Book Your Journey Now
            </button>
          </Link>
        </div>
      </section>

      {/* Chatbot, Campaign and Footer Components */}
      <FloatingTranslator />
      <ChatBot />
      <CampaignSection />
      <Footer />
    </div>
  );
};

export default About;